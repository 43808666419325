<div>
  <div class="position-sticky top-0 z-index-10 bg-white p-2">
    <div>
      <mat-form-field class="w-100">
        <mat-label>Search for answer...</mat-label>

        <input
          #searchInput matInput type="search"
          (keyup)="onSearchTextChange(searchInput.value)"
          (search)="onSearchTextChange(searchInput.value)"
          (blur)="onSearchTextChange(searchInput.value)"
        >
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="visibleQuestionAnswers" class="p-2">
    <div *ngIf="!visibleQuestionAnswers.length" class="text-center">
      <h3>No answers found :(</h3>

      <div class="mt-2">Reach out to us with your question.</div>
    </div>


    <mat-accordion multi>
      <ng-container *ngFor="let qa of visibleQuestionAnswers">
        <mat-expansion-panel (expandedChange)="delayedUpdateContentSize()" [expanded]="qa.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div>
                <div class="flex-row-center">
                  <div class="flex-grow-1">
                    {{ qa.question }}
                  </div>

                  <div class="ms-1 cursor-pointer" title="Copy link" (click)="copyLink(qa); $event.stopPropagation()">
                    <svg width="18" height="18"
                         class="cursor-pointer"
                         viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg"
                         fill-rule="evenodd"
                         clip-rule="evenodd">
                      <path
                        d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
                    </svg>
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="text-multiline-content">
            <div [innerHTML]="qa.answer"></div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>

  <div *ngIf="!visibleQuestionAnswers" class="text-center p-5">
    <svg xmlns="http://www.w3.org/2000/svg" style="margin: auto; background: transparent; display: block;" width="200px"
         height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#7bd8d1" stroke-width="4" r="16"
              stroke-dasharray="75.39822368615503 27.132741228718345">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
                          values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  </div>
</div>
