import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, firstValueFrom, map, Subject, take, timer } from 'rxjs';
import { parse as parseCSV } from 'csv-parse/sync';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from './injection-tokens';

export interface QuestionAnswer {
  question: string;

  answer: string;

  expanded: boolean;
}

export const SEARCH_QUERY_PARAM_NAME = 'search';
export const PARENT_QUERY_PARAM_NAME = 'parent';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  private _initialSearchText?: string | null;
  private _parentPageUrl?: string | null;

  private _contentHeight$ = new Subject<number>();
  private _searchTextChange = new Subject<string>();

  private _allQuestionAnswers?: QuestionAnswer[];
  private _questionAnswerIndex: {
    text: string;
    titleText: string;
    qa: QuestionAnswer;
  }[] = [];
  @ViewChild('searchInput')
  protected searchInput?: ElementRef;

  constructor(
    private _http: HttpClient,
    private _changeDetector: ChangeDetectorRef,
    @Inject(WINDOW) private _window: Window,
  ) {}

  public visibleQuestionAnswers?: QuestionAnswer[];

  public async ngOnInit() {
    const thisUrl = new URL(this._window.location.href);

    this._parentPageUrl = thisUrl.searchParams.get(PARENT_QUERY_PARAM_NAME);

    this._initialSearchText = (this._parentPageUrl ? new URL(this._parentPageUrl) : thisUrl).searchParams.get(SEARCH_QUERY_PARAM_NAME);

    this._contentHeight$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(height => {
      this._window.parent.postMessage(JSON.stringify({
        height: height,
      }), 'https://www.joinohana.io');
    });

    this._searchTextChange
      .pipe(
        debounceTime(50),
        map(_ => _?.trim()?.toLowerCase() || ''),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(searchText => {
        if (!searchText) {
          this.visibleQuestionAnswers = this._allQuestionAnswers;
        } else {
          const searchParts = searchText.split(' ');

          const exactFind = this._questionAnswerIndex?.find(_ => _.titleText === searchText);

          if (exactFind) {
            exactFind.qa.expanded = true;

            this.visibleQuestionAnswers = [exactFind.qa];
          } else {
            this.visibleQuestionAnswers = this._questionAnswerIndex?.filter(_ => searchParts.some(__ => _.text.includes(__)))?.map(_ => _.qa);
          }
        }

        this._changeDetector.markForCheck();

        this.delayedUpdateContentSize();
      });

    await this._loadQuestionsAsync();
  }

  ngAfterViewInit(): void {
    this.delayedUpdateContentSize();

    if (this._initialSearchText && this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.value = this._initialSearchText;
    }
  }

  public onSearchTextChange(text: string) {
    this._searchTextChange.next(text);
  }

  private async _loadQuestionsAsync() {
    const faqContentText = await firstValueFrom(this._http.get(
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vQ8bIQfGMebQ6PbFUp5LEJ5JSH-yu8auEQBVdYJseWZJ6YlLSbLMl2EHVwtf6oPrPsytwpcBAI10oTL/pub?output=csv',
      {
        responseType: 'text',
      }));

    const faqContent = <[string, string][]>parseCSV(faqContentText, {
      delimiter: ',',
    });

    if (!faqContent.length) {
      return;
    }

    if (faqContent[0][0] === 'Question' && faqContent[0][1] === 'Answer') {
      faqContent.splice(0, 1);
    }

    this._allQuestionAnswers = faqContent.map(_ => {
      return {
        question: _[0],
        answer: _[1].replaceAll(/\[([^\]]*)\]\(([^\)]*)\)/ig, '<a href="$2" target="_blank">$1</a>'),
        expanded: false,
      };
    });

    this._questionAnswerIndex = this._allQuestionAnswers.map(_ => {
      const lowerCaseQuestion = _.question.toLowerCase();

      return {
        text: lowerCaseQuestion + ' ' + _.answer.toLowerCase(),
        titleText: lowerCaseQuestion,
        qa: _,
      };
    });

    this.onSearchTextChange(this._initialSearchText || '');
  }

  public delayedUpdateContentSize() {
    timer(0, 100)
      .pipe(
        take(5),
      )
      .subscribe(() => {
        this._contentHeight$.next(this._window.document.body.scrollHeight);
      });
  }

  public copyLink(qa: QuestionAnswer) {
    const thisPageURL = new URL(this._parentPageUrl || this._window.location.href);

    const link = `${ thisPageURL.origin }${thisPageURL.pathname}?${ SEARCH_QUERY_PARAM_NAME }=${ encodeURIComponent(qa.question) }`;

    this._window.navigator.clipboard.writeText(link);
  }
}
